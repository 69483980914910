:root {
  --font-name: "Albert Sans", sans-serif;
  --font-size-base: 1rem;

  --border-radius-size: 8px;
  --breadcrumb-height: 40px;

  --sidebar-width-size: 201px;
  --collapsed-main-sidebar-width: 61px;
  --product-sidebar-width: 240px;

  --primary-color: #f8f8f8;
  --secondary-color: #1372c9;
  --surface-color: #0063db;
  --accent-color: #f8f8f8;
  --action-color: #c1c1c1;

  --mdc-filled-button-container-color: #0063db;
  --mdc-filled-text-field-container-color: #fff;
  --mdc-text-button-label-text-color: #00247a;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-text-button-horizontal-padding: 16px;
  --mdc-dialog-container-shape: 8px;
  --mat-dialog-headline-padding: 16px 24px 12px;
}
