.list-table-container {
  table,
  mat-paginator {
    background-color: #fdfdfd;
    border: 1px solid #d5d5d5;
    overflow: hidden;
  }

  table {
    border-top-left-radius: var(--border-radius-size);
    border-top-right-radius: var(--border-radius-size);

    thead {
      background-color: #f8f8f8;
      height: 36px;
    }

    tr {
      height: 36px;
    }

    td,
    th {
      border-color: #d5d5d5;
      text-wrap: nowrap;
    }

    .empty-list {
      padding: 0 1rem;
    }
  }

  mat-paginator {
    height: 36px;
    border-bottom-left-radius: var(--border-radius-size);
    border-bottom-right-radius: var(--border-radius-size);
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .loading-spinner-row {
    background-color: #fdfdfd;
    border-left: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
