@font-face {
  font-family: "Material Symbols Rounded Filled";
  font-style: normal;
  font-weight: 300;
  src: url("../../../public/fonts/material/mat-symbol-round-fill.woff2") format("woff2");
}

@font-face {
  font-family: "Material Symbols Rounded";
  font-style: normal;
  font-weight: 300;
  src: url("../../../public/fonts/material/mat-symbol-round.woff2") format("woff2");
}

.material-symbols {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.mat-symbol-round {
  font-family: "Material Symbols Rounded" !important;
  @extend .material-symbols;
}

.mat-symbol-round-fill {
  font-family: "Material Symbols Rounded Filled" !important;
  @extend .material-symbols;
}
